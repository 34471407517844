import React from "react";
import styled from "styled-components";
import { MOBILE_BREAK_POINT } from "../config/config";

export default function HomeAgencyCard({ agency }) {
  return (
    <S.CardWrap>
      <img
        src={`${process.env.NEXT_PUBLIC_CF_PUBLIC_URL}${agency.Key}`}
        alt={agency.key}
      />
    </S.CardWrap>
  );
}

const S = {};

S.CardWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100px;

  & > img {
    width: 228px;
    height: 100px;
    margin-left: 16px;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    height: 63px;
    & > img {
      width: 144px;
      height: 63px;
    }
  }
`;
