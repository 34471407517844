import React from "react";
import PropTypes from "prop-types";
import styled, { css as styledCss } from "styled-components";
import { titleSize } from "./styles/titleSize";
import { titleColor } from "./styles/titleColor";

function Title({ children, size, color, ...props }) {
  return (
    <S.Title size={size} color={color} {...props}>
      {children}
    </S.Title>
  );
}

export default React.memo(Title);

Title.defaultProps = {
  size: "medium",
  color: "black",
};

Title.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.string.isRequired,
};

const S = {};

S.Title = styled.h1`
  white-space: pre-wrap;
  margin: 0;
  ${(props) => styledCss`
        ${titleSize(props.size)}
        ${titleColor(props.color)}
    `}
`;
