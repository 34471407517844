import { keyframes } from "styled-components";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 90%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const fadeInUpShadow = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 90%, 0);
  }
  to {
    opacity: 0.7;
    transform: translate3d(0, 0, 0);
  }
`;

const slowDown = keyframes`
  0% {
    transform: scale(1, 0);
    transform-origin: 0% 0%;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0% 0%;
  }
  50.1% {
    transform: scale(1, 1);
    transform-origin: 0% 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0% 100%;
  }
`;

const transition = {
  fadeInUp,
  slowDown,
  fadeInUpShadow,
};

export default transition;
