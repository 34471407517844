import React from "react";
import PropTypes from "prop-types";
import styled, { css as styledCss } from "styled-components";
import { paragraphSize } from "./styles/paragraphSize";
import { paragraphColor } from "./styles/paragraphColor";

function Paragraph({ children, size, color, ...props }) {
  return (
    <S.Paragraph size={size} color={color} {...props}>
      {children}
    </S.Paragraph>
  );
}

export default React.memo(Paragraph);

Paragraph.defaultProps = {
  size: "medium",
  color: "black",
  onClick: () => {},
};

Paragraph.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
};

const S = {};

S.Paragraph = styled.p`
  white-space: pre-wrap;
  width: 100%;
  margin: 0;
  ${(props) => styledCss`
        ${paragraphSize(props.size)}
        ${paragraphColor(props.color)}
    `}
`;
