const colorBreakPoints = {
  black: `
      color: #000000;
      `,
  white: `
      color: #ffffff;
      `,
};

export const titleColor = (key) => {
  return colorBreakPoints[key];
};
