import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  MOBILE_BREAK_POINT,
  MOBILE_CONTENT_MIN_HEIGHT,
} from "../config/config";

export default function SliderContent({ content }) {
  return (
    <S.Container
      src={content.backgroundImg}
      mobileSrc={content.mobileBackgroundImg}
    />
  );
}

SliderContent.propTypes = {
  content: PropTypes.object.isRequired,
};

const S = {};

S.Container = styled.div`
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background-image: ${(props) => (props.src ? `url(${props.src})` : "")};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    min-height: ${MOBILE_CONTENT_MIN_HEIGHT}px;
  }
`;
