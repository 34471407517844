import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../components/Title";
import HomeAgencyCard from "./HomeAgencyCard";
import axios from "axios";

import {
  CLIENT_LOGOS_URL,
  CONTENT_MAX_WIDTH,
  INVESTOR_LOGOS_URL,
  MOBILE_BREAK_POINT,
  MOBILE_CONTENT_MAX_WIDTH
} from "../config/config";
import Triangle from "../components/Triangle";

export default function HomeAgencyList({ className }) {
  const [investors, setInvestors] = useState([]);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    axios.get(INVESTOR_LOGOS_URL).then((res) => {
      setInvestors(res.data.investors)
    }).catch(() => {
    })
    axios.get(CLIENT_LOGOS_URL).then((res) => {
      setClients(res.data.clients)
    }).catch(() => {
    })
  }, [])

  return (
    <S.ListWrap className={className}>
      <S.Section>
        <div>
          <Title color="black" size="large" style={{fontSize: "30px"}}>
            Investors
          </Title>
          <S.Wrap>
            <S.ContentsList>
              {investors?.map((investor, i) => (
                <HomeAgencyCard
                  key={`${investor.Key}${i}`}
                  agency={investor}
                />
              ))}
            </S.ContentsList>
          </S.Wrap>
        </div>
        <S.Divider />
        <div>
          <S.Wrap>
            <S.ContentsList>
              {clients?.map((client, i) => (
                <HomeAgencyCard key={`${client.Key}${i}`} agency={client} />
              ))}
            </S.ContentsList>
          </S.Wrap>
          <Title color="black" size="large" style={{textAlign: "right", fontSize: '30px'}}>
            Clients / Partners
          </Title>
        </div>
      </S.Section>
      <Triangle color="black" />
    </S.ListWrap>
  );
}

const S = {};
S.ListWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 105px 0;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    padding: 60px 0;
  }
`;

S.Wrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

S.Section = styled.section`
  width: ${CONTENT_MAX_WIDTH}px;
  margin: 0 auto;
  position: relative;

  & > div:nth-child(1),
  & > div:nth-child(3) {
    display: flex;
    padding-top: 2px;
  }
  & > div:nth-child(1) > div {
    justify-content: flex-end;
  }
  & > div:nth-child(3) > div {
    justify-content: flex-start;
  }
  & > div:nth-child(1) > div > div > div {
    justify-content: flex-end;
  }
  & > div:nth-child(3) > div > div > div {
    justify-content: flex-start;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: ${MOBILE_CONTENT_MAX_WIDTH}px;
    & h1 {
      margin-bottom: 20px;
    }
    & > div:nth-child(1) {
      flex-direction: column;
    }
    & > div:nth-child(3) {
      flex-direction: column-reverse;
      align-items: baseline;
    }
    & > div:nth-child(1) > div {
      justify-content: center;
    }
    & > div:nth-child(3) > div {
      justify-content: center;
    }
    & > div:nth-child(1) > div > div > div {
      justify-content: center;
    }
    & > div:nth-child(3) > div > div > div {
      justify-content: center;
    }
  }
`;

S.Divider = styled.div`
  width: 100%;
  height: 0.0625rem;
  border: solid 1px #eeeeee;
  margin: 2rem 0;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    border-color: white;
    margin: 37px 0;
  }
`;

S.ContentsList = styled.div`
  display: grid;
  grid-auto-rows: minmax(116px, auto);
  grid-template-columns: repeat(4, 244px);
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    grid-auto-rows: minmax(71px, auto);
    grid-template-columns: repeat(2, 144px);
  }
`;

S.AgencyCard = styled.div`
  display: flex;
  align-items: center;

  & > img {
    width: 228px;
    hieght: 100px;
    migrin-left: 16px;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    & > img {
      width: 144px;
      height: 63px;
      margin-left: 0;
    }
  }
`;
