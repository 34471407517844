import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import Footer from "./Footer";
import HomeAgencyList from "./HomeAgencyList";
import HomeProductList from "./HomeProductList";
import {
  COMPANY_INFO_URL,
  CONTENT_MAX_WIDTH,
  FOOTER_CONTENT,
  HEADER_CONTENT,
  MOBILE_BREAK_POINT,
  PRODUCT_CONTENT,
} from "../config/config";
import GoTopButton from "../components/GoToButton";
import throttle from "lodash/throttle";
import { gsap, Power2 } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import HomeSlider from "./HomeSlider";
import JocoosWhiteLogo from "../assets/images/JocoosWhiteLogo.svg";
import JocoosBlackLogo from "../assets/images/JocoosBlackLogo.svg";
import WhiteBlog from "../assets/images/WhiteBlog.svg";
import BlackBlog from "../assets/images/BlackBlog.svg";
import WhiteFacebook from "../assets/images/WhiteFacebook.svg";
import BlackFacebook from "../assets/images/BlackFacebook.svg";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export default function HomeLayout() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBlackLogo, setIsBlackLogo] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    address: "",
    email: "",
    phone: "",
  });

  const goToSection = throttle((i, anim) => {
    if (MOBILE_BREAK_POINT >= window.innerWidth) return;
    setCurrentIndex(i);
    gsap.set("html", { overflow: "hidden" });
    gsap.set(".logo", { opacity: 0 });

    gsap.to(window, {
      scrollTo: {
        y: i * window.innerHeight,
        autoKill: false,
      },
      ease: Power2.easeIn,
      duration: 0.7,
      overwrite: true,
      onComplete: () => {
        setTimeout(() => {
          gsap.set("html", { overflow: "auto" });
          gsap.to(window, {
            y: i * window.innerHeight,
            autoKill: false,
          });
        }, 700);
        gsap.set(".logo", { opacity: 1 });
        if (i * window.innerHeight <= 100) {
          setIsBlackLogo(false);
        } else {
          setIsBlackLogo(true);
        }
        if (i == 2) {
          gsap.set(".logo", {
            background: "white",
          });
        } else {
          gsap.set(".logo", { border: "none", background: "transparent" });
        }
      },
    });
    if (anim) {
      anim.restart();
    }
  }, 250);

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
      gsap.core.globals("ScrollTrigger", ScrollTrigger);
      gsap.core.globals("ScrollToPlugin", ScrollToPlugin);

      gsap.utils.toArray(".panel").forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          onEnter: () => goToSection(i),
        });

        ScrollTrigger.create({
          trigger: panel,
          start: "bottom bottom",
          onEnterBack: () => goToSection(i),
        });
      });
    }
    return () => {};
  }, []);

  useEffect(() => {
    axios.get(COMPANY_INFO_URL).then((result) => {
      if (result.data) {
        setCompanyInfo({
          address: result.data.address_en,
          email: result.data.support_email,
          phone: result.data.company_phone,
        });
      }
    });
  }, []);

  return (
    <>
      <S.Main>
        <S.LogoWrap className="logo">
          <div>
            <img
              src={isBlackLogo ? JocoosBlackLogo : JocoosWhiteLogo}
              alt="jocoos_logo"
            />
            {/* <div>
              <a
                href="https://blog.naver.com/jocoos12"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={isBlackLogo ? BlackBlog : WhiteBlog}
                  alt="jocoos_blog"
                />
              </a>
              <a
                href="https://www.facebook.com/jocoos"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={isBlackLogo ? BlackFacebook : WhiteFacebook}
                  alt="jocoos_facebook"
                />
              </a>
            </div> */}
          </div>
        </S.LogoWrap>
        <S.Section className="panel">
          <HomeSlider className="pc" contents={HEADER_CONTENT} />
        </S.Section>

        <S.Section className="panel">
          <HomeProductList className="product-pc" contents={PRODUCT_CONTENT} />
        </S.Section>

        <S.AgencySection className="panel">
          <HomeAgencyList />
        </S.AgencySection>

        <Footer
          className="pc"
          contents={`${companyInfo.address}\n${companyInfo.email}\n${companyInfo.phone}`}
        />
        <GoTopButton />
        {/* <S.Group>
          <S.Indicator
            i={currentIndex}
            active={currentIndex === 0}
            onClick={() => goToSection(0)}
          />
          <S.Indicator
            i={currentIndex}
            active={currentIndex === 1}
            onClick={() => goToSection(1)}
          />
          <S.Indicator
            i={currentIndex}
            active={currentIndex === 2}
            onClick={() => goToSection(2)}
          />
        </S.Group> */}
      </S.Main>
    </>
  );
}

const S = {};

S.Main = styled.main`
  position: relative;
  overflow: hidden;

  display: block;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    display: none !important;
  }

  & .pc {
    display: block;
    @media (max-width: ${MOBILE_BREAK_POINT}px) {
      display: none !important;
    }
  }
  & .mobile {
    display: none;
    @media (max-width: ${MOBILE_BREAK_POINT}px) {
      display: block !important;
    }
  }
  & .product-pc {
    display: flex;
    @media (max-width: ${MOBILE_BREAK_POINT}px) {
      display: none !important;
    }
  }
  & .product-mobile {
    display: none;
    @media (max-width: ${MOBILE_BREAK_POINT}px) {
      display: flex !important;
    }
  }
`;

S.Section = styled.section``;

S.AgencySection = styled.div`
  min-height: 60rem;
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.LogoWrap = styled.div`
  position: fixed;
  padding: 2rem 0;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  transition: opacity 0.5s ease-in;
  & > div {
    display: flex;
    justify-content: space-between;
    width: ${CONTENT_MAX_WIDTH}px;
    margin: 0 auto;
  }

  & > div > img {
    height: 36px;
  }

  & > div > div img:last-child {
    margin-left: 10px;
  }
`;

S.Group = styled.div`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

S.Indicator = styled.div`
  width: 5px;
  height: 35px;
  border-radius: 4px;
  border: "1px solid white";
  background-color: ${(props) => (props.active ? "#ffc41f" : "white")};
`;
