import React, { useCallback, useEffect, useState } from "react";
import throttle from "lodash/throttle";
import styled from "styled-components";

export default function GoTopButton() {
  const [visible, setVisible] = useState(false);

  const goToTop = useCallback(() => {
    window.document.body.scrollTop = 0;
    window.document.documentElement.scrollTop = 0;
  });

  const onToggleButton = useCallback(
    throttle(() => {
      const scrollY =
        ((window?.scrollY + window?.innerHeight) /
          window.document.body.clientHeight) *
        100;
      if (scrollY >= 50) {
        if (!visible) setVisible(true);
      } else {
        setVisible(false);
      }
    }, 250),
    [visible]
  );

  useEffect(() => {
    window?.addEventListener("scroll", onToggleButton);

    return () => {
      window?.removeEventListener("scroll", onToggleButton);
    };
  });

  return (
    <>
      <S.GoToTop
        visible={visible}
        onClick={goToTop}
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <g transform="translate(-268 -2245) translate(268 2245)">
              <circle
                cx="18"
                cy="18"
                r="17.5"
                fill="#FFF"
                stroke="#979797"
                opacity=".5"
              />
              <path stroke="#CBCBCB" d="M11 16l7-6 7 6m-7-6v18" />
            </g>
          </g>
        </g>
      </S.GoToTop>
    </>
  );
}

const S = {};

S.GoToTop = styled.svg`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  transition: all 0.5s ease-in;
  cursor: ${(props) => (props.visible ? "pointer" : "auto")};
  opacity: ${(props) => (props.visible ? "1" : "0")};
`;
