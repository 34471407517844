import { MOBILE_BREAK_POINT } from "../../../config/config";

const sizeBreakPoints = {
  small: `
            height: 1.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 0.875rem;
      `,
  medium: `
        width: 180px;
        height: 42px;
        border-radius: 1.625rem;

        @media (max-width: ${MOBILE_BREAK_POINT}px) {
          font-size: 12px;
          margin: 0; 
          width: 137px;
          height: 36px;            
        }
      `,
  large: `
            height: 2.5rem;
            padding-left: 1.125rem;
            padding-right: 1.125rem;
            & + & {
            margin-left: 0.875rem;
            }
            font-size: 1.125rem;

      `,
};

export const buttonSize = (key) => {
  return sizeBreakPoints[key];
};
