const colorBreakPoints = {
  black: `
    color: #000000;
    `,
  white: `
    color: #ffffff;
    `,
};

export const paragraphColor = (key) => {
  return colorBreakPoints[key];
};
