import { MOBILE_BREAK_POINT } from "../../../config/config";

const sizeBreakPoints = {
  small: `
      opacity: 0.6;
      font-family: 'Noto Sans', sans-serif;
      font-size: 1.125rem;
      line-height: 1.5;
      color: #000000;
      @media (max-width: ${MOBILE_BREAK_POINT}px) {
        font-size: 12px;
        line-height: 1.58;
      }
        `,
  medium: `
      line-height: 1.56;
      letter-spacing: normal;
      font-size: 16px;
      font-weight: normal;

      @media (max-width: ${MOBILE_BREAK_POINT}px) {
        font-size: 12px;
        line-height: 1.58;
      }
        `,
  large: `
        `,
};

export const paragraphSize = (key) => {
  return sizeBreakPoints[key];
};
