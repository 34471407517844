import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Footer from "../Footer";
import HomeAgencyList from "../HomeAgencyList";
import HomeProductList from "../HomeProductList";
import {
  COMPANY_INFO_URL,
  CONTENT_MAX_WIDTH,
  MOBILE_BREAK_POINT,
  MOBILE_CONTENT_MAX_WIDTH,
  MOBILE_FOOTER_CONTENT,
  MOBILE_HEADER_CONTENT,
  MOBILE_PRODUCT_CONTENT,
} from "../../config/config";
import GoTopButton from "../../components/GoToButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import HomeSlider from "../HomeSlider";
import JocoosWhiteLogo from "../../components/Logo/JocoosWhiteLogo";
import JocoosBlackLogo from "../../components/Logo/JocoosBlackLogo";
import WhiteBlog from "../../assets/images/WhiteBlog.svg";
import BlackBlog from "../../assets/images/BlackBlog.svg";
import WhiteFacebook from "../../assets/images/WhiteFacebook.svg";
import BlackFacebook from "../../assets/images/BlackFacebook.svg";

gsap.registerPlugin(ScrollTrigger);

export default function MobileHomeLayout() {
  const [isBlackLogo, setIsBlackLogo] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({
    address: "",
    email: "",
    phone: "",
  });

  const leaveSection = (i, anim) => {
    if (MOBILE_BREAK_POINT < window.innerWidth) return;
    if (i == 0) {
      setIsBlackLogo(true);
    }
    if (i == 1) {
      gsap.set(".mobile-logo", {
        background: "white",
      });
    } else {
      if (i !== 2)
        gsap.set(".mobile-logo", { border: "none", background: "transparent" });
    }
  };
  const leaveBackSection = (i) => {
    if (MOBILE_BREAK_POINT < window.innerWidth) return;
    if (i == 1 || i == 0) {
      setIsBlackLogo(false);
    } else {
      setIsBlackLogo(true);
    }
  };
  const enterBackSection = (i) => {
    if (MOBILE_BREAK_POINT < window.innerWidth) return;
    if (i == 1 || i == 0) {
      gsap.set(".mobile-logo", { border: "none", background: "transparent" });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals("ScrollTrigger", ScrollTrigger);

      gsap.utils.toArray(".mobile-panel").forEach((panel, i) => {
        ScrollTrigger.create({
          trigger: panel,
          onLeave: () => leaveSection(i),
        });

        ScrollTrigger.create({
          trigger: panel,
          start: "bottom bottom",
          onLeaveBack: () => leaveBackSection(i),
          onEnterBack: () => enterBackSection(i),
        });
      });
    }
    return () => {};
  }, []);

  useEffect(() => {
    axios.get(COMPANY_INFO_URL).then((result) => {
      if (result.data) {
        setCompanyInfo({
          address: result.data.address_en,
          email: result.data.support_email,
          phone: result.data.company_phone,
        });
      }
    });
  }, []);

  return (
    <>
      <S.Main>
        <S.LogoWrap className="mobile-logo">
          <div>
            {isBlackLogo ? <JocoosBlackLogo /> : <JocoosWhiteLogo />}
            {/* <div>
              <a href='https://blog.naver.com/jocoos12' target='_blank' rel='noopener noreferrer'>
                <img src={isBlackLogo ? BlackBlog : WhiteBlog} alt='jocoos_blog' />
              </a>
              <a href='https://www.facebook.com/jocoos' target='_blank' rel='noopener noreferrer'>
                <img src={isBlackLogo ? BlackFacebook : WhiteFacebook} alt='jocoos_facebook' />
              </a>
            </div> */}
          </div>
        </S.LogoWrap>
        <S.Section className="mobile-panel">
          <HomeSlider className="mobile" contents={MOBILE_HEADER_CONTENT} />
        </S.Section>

        <S.Section className="mobile-panel">
          <HomeProductList
            className="product-mobile"
            contents={MOBILE_PRODUCT_CONTENT}
          />
        </S.Section>

        <S.AgencySection className="mobile-panel">
          <HomeAgencyList />
        </S.AgencySection>

        <Footer
          className="mobile mobile-panel"
          contents={`${companyInfo.address}\n${companyInfo.email}\n${companyInfo.phone}`}
        />
        <GoTopButton />
      </S.Main>
    </>
  );
}

const S = {};

S.Main = styled.div`
  position: relative;
  overflow: hiden;

  display: none;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    display: block !important;
  }

  & .mobile {
    display: none;
    @media (max-width: ${MOBILE_BREAK_POINT}px) {
      display: block !important;
    }
  }

  & .product-mobile {
    display: none;
    @media (max-width: ${MOBILE_BREAK_POINT}px) {
      display: flex !important;
    }
  }
`;

S.Section = styled.section``;

S.AgencySection = styled.div`
  min-height: 60rem;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    width: 100vw;
    height: 100%;
  }
`;

S.LogoWrap = styled.div`
  position: fixed;
  padding: 2rem 0;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  transition: opacity 0.5s ease-in;

  & > div > svg {
    height: 24px;
  }

  & a img {
    height: 36px;
  }

  & a img:last-child {
    margin-left: 10px;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${CONTENT_MAX_WIDTH}px;
    margin: 0 auto;
  }
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    & > div {
      width: ${MOBILE_CONTENT_MAX_WIDTH}px;
    }
    & svg {
      width: 110px;
      height: 21px;
    }
  }
  @media (max-width: 360px) {
    padding-left: 0;
  }

  & > div > div img:last-child {
    height: 36px;
  }
`;
