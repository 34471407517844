const colorBreakPoints = {
  whitePink: `
  background-color: transparent;
  border: solid 1px rgba(255,255,255,0.4);
  color: #ffffff;
  &:hover {
      background: #d51755;
      border: none;
  }
    `,
  whiteYellow: `
  background-color: transparent;
  border: solid 1px rgba(255,255,255,0.4);
  color: #ffffff;
  &:hover {
      background: #f8a600;
      border: none;
  }
    `,
  whiteBlue: `
    background-color: transparent;
    border: solid 1px rgba(255,255,255,0.4);
    color: #ffffff;
    &:hover {
        background: #4C6fff;
        border: none;
    }
      `,
};

const focusedColorBreakPoints = {
  whitePink: `
      color: #ffffff;
      background: #d51755;
      border: none;
    `,
  whiteYellow: `
      color: #ffffff;
      background: #f8a600;
      border: none;
    `,
  whiteBlue: `
      color: #ffffff;
      background: #4C6fff;
      border: none;
  `,
};

export const buttonColor = (key, focus) => {
  return focus ? focusedColorBreakPoints[key] : colorBreakPoints[key];
};
