import React from "react";
import styled from "styled-components";
import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import { MOBILE_BREAK_POINT } from "../config/config";

export default function HomeProductCard({ content }) {
  return (
    <S.CardWrap>
      <img src={content.image} alt={`${content.title} image`} />
      <S.Content>
        <Title size="small" color="black">
          {content.title}
        </Title>
        <Paragraph size="small" color="black">
          {content.paragraph}
        </Paragraph>
      </S.Content>
    </S.CardWrap>
  );
}

const S = {};

S.CardWrap = styled.div`
  width: 25.625rem;
  & > img {
    margin-bottom: 50px;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    & > img {
      margin-right: 18px;
      width: 64px;
      height: 64px;
    }
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

S.Content = styled.div`
  & h1 {
    margin-bottom: 20px;
  }
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    & h1 {
      margin-bottom: 0.7rem;
    }
  }
`;
