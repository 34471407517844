import React from "react";
import PropTypes from "prop-types";
import styled, { css as styledCss } from "styled-components";
import { buttonSize } from "./styles/buttonSize";
import { buttonColor } from "./styles/buttonColor";

function Button({ children, size, color, focus, ...props }) {
  return (
    <S.Button color={color} size={size} focus={focus} {...props}>
      {children}
    </S.Button>
  );
}

export default React.memo(Button);

Button.defaultProps = {
  size: "medium",
  onClick: () => {},
};

Button.propTypes = {
  size: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.string.isRequired,
};

const S = {};

S.Button = styled.button`
  border-radius: 1.625rem;
  cursor: pointer;
  font-size: 1rem;

  &:focus {
    outline-style: none;
    box-shadow: none;
  }

  & + & {
    margin-left: 1.5rem;
  }

  @media (max-width: 680px) {
    & + & {
      margin-left: 0rem;
    }
  }

  ${(props) => styledCss`
        ${buttonSize(props.size)}
        ${buttonColor(props.color, props.focus)}
    `}
`;
