import Head from "next/head";
import styled from "styled-components";
import throttle from "lodash/throttle";
import { IndexSEO } from "../config/next-seo.config";
import {
  MOBILE_BREAK_POINT,
  SITE_DESC,
  SITE_NAME,
  SITE_URL,
} from "../config/config";
import HomeLayout from "../layout/HomeLayout";
import MobileHomeLayout from "../layout/mobile/MobileHomeLayout";
import { useCallback, useEffect, useState } from "react";

function Home() {
  const seoConfig = {
    url: `${SITE_URL}`,
    title: `${SITE_NAME}`,
    description: `${SITE_DESC}`,
  };

  const isClient = typeof window === "object";

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = throttle(() => {
      setWindowSize(getSize());
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, [250]);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSize, isClient]);

  return (
    <>
      <Head>
        <title>{seoConfig.title}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <IndexSEO config={seoConfig} />
      {windowSize.width <= MOBILE_BREAK_POINT ? (
        <MobileHomeLayout />
      ) : (
        <HomeLayout />
      )}
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {},
  };
}

export default Home;

const S = {};

S.Main = styled.main``;

S.PcWrap = styled.div`
  display: block;
  @media (max-width: 500px) {
    display: none !important;
  }
`;

S.MobileWrap = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`;
