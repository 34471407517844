import React from "react";
import styled from "styled-components";
import Title from "../components/Title";
import HomeProductCard from "./HomeProductCard";
import Triangle from "../components/Triangle";
import {
  CONTENT_MAX_WIDTH,
  MOBILE_BREAK_POINT,
  MOBILE_CONTENT_MAX_WIDTH,
  MOBILE_CONTENT_MIN_HEIGHT,
} from "../config/config";

export default function HomeProductList({ contents, className }) {
  return (
    <S.Section className={className}>
      <div>
        <Title color="black" size="large">
          What we do
        </Title>
        <S.CardListWrap>
          {contents.map((content) => (
            <HomeProductCard key={content.title} content={content} />
          ))}
        </S.CardListWrap>
      </div>
      <Triangle color="black" />
    </S.Section>
  );
}

const S = {};

S.Section = styled.div`
  position: relative;
  height: 100vh;
  min-height: initial;
  background-color: #ffc41f;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    position: relative;
    width: ${CONTENT_MAX_WIDTH}px;
    margin: 0 auto;
  }
  & > div > h1 {
    margin-bottom: 80px;
  }

  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    min-height: ${MOBILE_CONTENT_MIN_HEIGHT}px;
    & > div {
      width: ${MOBILE_CONTENT_MAX_WIDTH}px;
    }
    & > div > h1 {
      margin-bottom: 40px;
    }

    & > div > img {
      box-sizing: content-box;
      padding: 0 1.5rem;
      width: 110px;
      height: 21px;
    }
  }
`;

S.CardListWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    flex-direction: column;
  }
`;
