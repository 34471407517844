import { MOBILE_BREAK_POINT } from "../../../config/config";

const sizeBreakPoints = {
  small: `
        font-size: 1.625rem;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        line-height: 1.625rem;

        @media (max-width: ${MOBILE_BREAK_POINT}px) {
          font-size: 18px;
          font-weight: bold;
          line-height: 18px;
        }
          `,
  medium: `
        font-size: 64px;
        font-weight: 900;
        font-family: 'Montserrat', sans-serif;
        line-height: normal;

        @media (max-width: ${MOBILE_BREAK_POINT}px) {
          font-size: 32px;
          font-weight: 900;
          line-height: 2.5rem;
        }
          `,
  large: `
        font-size: 4.5rem;
        font-weight: 900;
        font-family: 'Montserrat', sans-serif;
        line-height: 4.5rem;

        
        @media (max-width: ${MOBILE_BREAK_POINT}px) {
          font-size: 32px;
          font-weight: 900;
          line-height: 32px;
        }
          `,
};

export const titleSize = (key) => {
  return sizeBreakPoints[key];
};
