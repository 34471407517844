import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MOBILE_BREAK_POINT } from "../../config/config";

function Triangle({ color }) {
  return <S.Triangle color={color} />;
}

export default React.memo(Triangle);

Triangle.defaultProps = {
  color: "black",
};

Triangle.propTypes = {
  color: PropTypes.string,
};

const S = {};

S.Triangle = styled.h1`
  display: none;
  margin: 0 !important;
  position: absolute;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: ${(props) => (props.color === "white" ? "white" : "black")};
  height: 20px;
  width: 20px;
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%) rotate(45deg);
  @media (max-width: ${MOBILE_BREAK_POINT}px) {
    display: block;
  }
`;
